export const SidebarContent = [
    {
        title: "Home", 
        link: "/"
    }, 

    {
        title: "About", 
        link: "/about"
    }, 

    {
        title: "Code", 
        link: "/code"
    },

    
    {
        title: "Writing", 
        link: "/writing"
    }



]

export default SidebarContent;
